import React, { useContext } from 'react';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { processRoutesRecursively } from 'utils/helpers/routesHelper';
import routes from './routeList';
import { AuthContext } from 'context/auth.context';
import { loggedOutSubroutesPaths } from './routesPaths';

const AppRoutes = () => {
    const { isAuthenticated, isTestExpired, userType, userData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const routesMapped = React.useMemo(() => {
        return processRoutesRecursively(
            routes,
            isAuthenticated,
            userData?.onboardingPending ?? false,
            userType,
            isTestExpired,
        );
    }, [isAuthenticated, isTestExpired, userType, userData?.onboardingPending]);

    React.useEffect(() => {
        const { generatePassword, replacePassword } = loggedOutSubroutesPaths;
        if (
            pathname.endsWith('/') &&
            pathname !== '/' &&
            ![
                generatePassword,
                replacePassword,
                `${generatePassword}/`,
                `${replacePassword}/`,
            ].includes(pathname)
        )
            navigate(pathname.slice(0, -1));
    }, [pathname]);

    return (
        <>
            <Routes>{routesMapped}</Routes>
        </>
    );
};

export default AppRoutes;
